
export const webPorfolio = [
    {
        "title":"HardCo.Social",
        "productImg":"assets/facebookClone/facebookClone_5.png",
        "desc":"一個模仿Facebook聊天室、好友申請、貼文系統的Web App",
        "video":"demo.mp4",
        "url":"https://hardco-social.xyz/",
        "featured":[
            {
                "title":"發文留言按讚系統"
            },
            {
                "title":"好友即時聊天系統"
            },
            {
                "title":"完全響應式設計"
            }
        ],
        "story":[
            {
                "title":"想要模仿馬克·祖克柏在大學宿舍裡打造出Facebook的感覺"
            },
            {
                "title":"利用Socket.io打造連線機制以及打造即時聊天系統"
            },
            {
                "title":"Timeline Post的運作以及好友申請的資料庫架構"
            },
            {
                "title":"串接imgur API打造簡單的圖片上傳儲存系統"
            }
        ],
        "userflow":[
            {
                "img":"assets/facebookClone/userflow1.png"
            },
            {
                "img":"assets/facebookClone/userflow2.png"
            }
        ],
        "system":[
            {
                "languageIcon":"assets/system/reactIcon.png",
                "width":"150px",
                "height":"150px",
                "library":[
                    {
                        "title":"React-Hook"
                    },
                    {
                        "title":"React-Responsive"
                    },
                    {
                        "title":"React-Router-dom"
                    },
                    {
                        "title":"Socket.io-client"
                    },
                    {
                        "title":"React-jwt"
                    },
                    {
                        "title":"React-infinite-scroll-component"
                    },
                    {
                        "title":"axios"
                    }
                ]
            },
            {
                "languageIcon":"assets/system/expressIcon.png",
                "width":"150px",
                "height":"150px",
                "library":[
                    {
                        "title":"cors"
                    },
                    {
                        "title":"express-jwt"
                    },
                    {
                        "title":"express-validator"
                    },
                    {
                        "title":"helmet"
                    },
                    {
                        "title":"jsonwebtoken"
                    },
                    {
                        "title":"socket.io"
                    }
                ]
            },
            {
                "languageIcon":"assets/system/MongoDBIcon.jpeg",
                "width":"150px",
                "height":"150px",
                "library":[
                    {
                        "title":"Mongoose"
                    }
                ]
            }
        ],
        "finalDesign":[
            {
                "link":"assets/facebookClone/S__45096962.jpg"
            },
            {
                "link":"assets/facebookClone/S__45096964.jpg"
            },
            {
                "link":"assets/facebookClone/S__45096965.jpg"
            },
            {
                "link":"assets/facebookClone/S__45096966.jpg"
            },
            {
                "link":"assets/facebookClone/S__45096967.jpg"
            },
            {
                "link":"assets/facebookClone/S__45096968.jpg"
            },
            {
                "link":"assets/facebookClone/S__45096969.jpg"
            },
            {
                "link":"assets/facebookClone/S__45096970.jpg"
            },
            {
                "link":"assets/facebookClone/S__45096971.jpg"
            },
            {
                "link":"assets/facebookClone/S__45096972.jpg"
            }
        ]
    },
    {
        "title":"HardCo.Todo",
        "productImg":"assets/todo/hardco-todo_1.png",
        "video":"todo_2.mov",
        "url":"http://5.181.217.243:3000",
        "desc":"一個模仿Microsoft.Todo創造清單、打造各種客製化todo的Web App",
        "featured":[
            {
                "title":"清單分類系統"
            },
            {
                "title":"客製化待辦事項系統"
            },
            {
                "title":"語言切換系統"
            },
            {
                "title":"完全響應式設計"
            }
        ],
        "story":[
            {
                "title":"學習Redux對所有的State做流程控制"
            },
            {
                "title":"對自己現在用的Todolist系統都沒有那麼滿意"
            },
            {
                "title":"用過Microsoft的Todo之後覺得很好用，想要打造一個相同的試試看"
            }
        ],
        "userflow":[
            {
                "img":"assets/todo/userflow1.png"
            },
            {
                "img":"assets/todo/userflow2.png"
            }
        ],
        "system":[
            {
                "languageIcon":"assets/system/reactIcon.png",
                "library":[
                    {
                        "title":"React-Hook"
                    },
                    {
                        "title":"typescript"
                    },
                    {
                        "title":"formik"
                    },
                    {
                        "title":"i18next"
                    },
                    {
                        "title":"react-redux"
                    },
                    {
                        "title":"@material-ui"
                    },
                    {
                        "title":"axios"
                    }
                ]
            },
            {
                "languageIcon":"assets/system/expressIcon.png",
                "library":[
                    {
                        "title":"cors"
                    },
                    {
                        "title":"pg"
                    },
                    {
                        "title":"express-session"
                    }
                ]
            },
            {
                "languageIcon":"assets/system/postgresql.png",
                "library":[
                    {
                        "title":"type-orm"
                    }
                ]
            },
            {
                "languageIcon":"assets/system/docker.png",
                "library":[
                    {
                        "title":"docker-compose"
                    }
                ]
            }
        ],
        "finalDesign":[
            {
                "link":"assets/todo/S__45105156.jpg"
            },
            {
                "link":"assets/todo/S__45105158.jpg"
            },
            {
                "link":"assets/todo/S__45105159.jpg"
            },
            {
                "link":"assets/todo/S__45105160.jpg"
            },
            {
                "link":"assets/todo/S__45105161.jpg"
            },
            {
                "link":"assets/todo/S__45105162.jpg"
            }
        ]
    }
];

export const blogPorfolio = [
    {
        "title":"Python爬蟲 - IG 抽獎機器",
        "productImg":"assets/blog/1.png",
        "url":"http://hardcodreamerblog.com/python-爬蟲教學-我們來做一個ig抽獎神器吧！（下）/"
    },
    {
        "title":"Python爬蟲 - 搶購機器",
        "productImg":"assets/blog/3.png",
        "url":"http://hardcodreamerblog.com/python爬蟲教學-試著作自己的搶購機器吧上/"
    },
    {
        "title":"Wordpress - AJAX應用",
        "productImg":"assets/blog/4.png",
        "url":"http://hardcodreamerblog.com/wordpress-admin-ajax-4/"
    },
    {
        "title":"寫App時應該要注意的五件事",
        "productImg":"assets/blog/2.png",
        "url":"http://hardcodreamerblog.com/寫app入門時你應該要注意的五件事-創業日記5/"
    }
]

export const appPorfolio = [
    {
        "title":"邊吃邊玩",
        "productImg":"assets/mobile/tripfinder.png",
        "url":"https://play.google.com/store/apps/details?id=tripfinder.v1.myapp",
        "mobilesize":true
    },
    {
        "title":"救難即時通",
        "productImg":"assets/mobile/escape.jpg",
        "url":"https://apps.apple.com/tw/app/id1549563679",
        "mobilesize":true
    },
    {
        "title":"FitUr", 
        "productImg":"assets/mobile/fitur.png",
        "url":"https://drive.google.com/file/d/1-IjvAFuOruSoqn1ajTy8J-vPwGKqd5pw/view?usp=sharing",
        "mobilesize":true
    },
]

export const microService = [
    {
        "title":"PS5搶購機器",
        "productImg":"assets/microservice/ps5_1.png",
        "url":"http://hardcodreamerblog.com/line-notify-testing/",

    },
    {
        "title":"中山小幫手",
        "productImg":"assets/microservice/linenotify.png",
        "url":"https://github.com/hardco2020/BuyStuffAutomatically_Selenium"
        
    }
]